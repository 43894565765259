import React from "react";
import { Helmet } from "react-helmet";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import Footer from "../components/FOOTER/Footer";
import { Link } from "react-router-dom";
import residents from "../assets/images/resident-new.webp";
import admin from "../assets/images/adminHero.webp";

const GetStartedPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Get Started - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Get Started - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        <p className="font-sora text-green-500 font-semibold pt-2.5 md:pt-5  text-[26px] leading-[32px] md:text-4xl lg:text-5xl xl:text-6xl text-center">
          Let’s get you started
        </p>

        <div className="h-4 lg:h-6"></div>
        <p className=" w-4/5 md:w-2/3 text-center  text-sm  md:text-base lg:text-xl xl:text-[30px]  md:leading-[28px] mx-auto lg:font-semibold max-w-[926px] leading-[22px] font-inter  lg:font-sora xl:leading-[66px]">
          Choose your category
        </p>
        <div className="h-12"></div>
        <div className="flex justify-center flex-col md:flex-row md:h-fit">
          <Link
            to="/how-it-works-for-residents"
            className="w-full md:w-1/2 max-w-[520px] rounded-xl border border-new-grey-200 p-6 bg-grey-50 h-full block
            group hover:bg-green-500 duration-500 transition-all xl:p-10
            "
          >
            <div className="overflow-hidden center rounded-[7px] h-[200px] lg:h-[310px] ">
              <img src={residents} alt="" className="h-full object-cover" />
            </div>
            <div className="h-6 md:h-10"></div>
            <div className="font-inter">
              <p className="font-semibold md:text-xl lg:text-2xl group-hover:text-light-green-400 duration-500 transition-all">
                Resident of an estate?
              </p>
              <div className="h-3 md:h-5"></div>
              <p className="text-xs text-new-grey-400 md:text-lg lg:text-xl group-hover:text-white duration-500 transition-all">
                Download and get started with the Venco App to seamless living
                experiences.
              </p>
            </div>
            <div className="h-6 md:h-10"></div>
            <button className="primaryButton w-full md:w-fit px-10">
              Join your community
            </button>
          </Link>
          <div className="w-6 xl:w-10 h-6"></div>
          <Link
            to="/onboard"
            className="w-full md:w-1/2 max-w-[520px] rounded-xl border border-new-grey-200 p-6 bg-grey-50 
            group hover:bg-green-500 duration-500 transition-all xl:p-10
            flex flex-col"
          >
            <div className="overflow-hidden center rounded-[7px] h-[200px] lg:h-[310px] ">
              <img src={admin} alt="" className="h-full object-cover" />
            </div>
            <div className="h-6 md:h-10"></div>
            <div className="font-inter">
              <p className="font-semibold md:text-xl lg:text-2xl group-hover:text-light-green-400 duration-500 transition-all">
                Estate or Community Manager?
              </p>
              <div className="h-3 md:h-5"></div>
              <p className="text-xs text-new-grey-400 md:text-lg lg:text-xl group-hover:text-white duration-500 transition-all">
                Get started by onboarding your community or estate on our
                platform
              </p>
            </div>
            <div className="h-6 md:h-10 "></div>
            <button className="primaryButton md:w-fit px-10 md:mt-auto">
              Set up your community
            </button>
          </Link>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default GetStartedPage;
